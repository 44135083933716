@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300|Oswald);
@import "fonts/Fonts.css";

html,
body,
#root {
  height: 100%;
}

body {
  /* font-family: 'Arial'; */
  font-family: Arial, sans-serif !important;
}

.bg-blue {
  background-color: #021d58 !important;
}

.btn-tab {
  color: white !important;
  background-color: #021d58 !important;
  z-index: 10;
}

.form-wrap label.form-label {
  color: #222222;
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 4px;
  display: block;
  text-align: left;
}

.form-wrap input,
.form-wrap select {
  border-radius: 5px;
  border: 0.5px #aaaaaa solid;
  width: 100%;
  margin-bottom: 30px;
  font-weight: 700;
  word-wrap: break-word;
  padding: 13px 20px;
  color: #012269;
  font-size: 13px;
  font-family: Arial;
  font-weight: 700;
  word-wrap: break-word;
}

.form-wrap input:focus,
.form-wrap select:focus {
  outline: none;
  box-shadow: none;
}

.btn.btn-prim {
  padding: 10px 10px;
  width: 100%;
  line-height: 19px;
  font-size: 13px;
  font-family: Arial;
  font-weight: 500;
  word-wrap: break-word;
  background: rgba(1, 34, 105, 1);
  color: #fff;
  border: 1px solid rgba(1, 34, 105, 1);
  transition: 0.3s;
}

.btn.btn-prim:hover,
.btn.btn-prim:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(1, 34, 105, 1);
  background: #fff;
  color: rgba(1, 34, 105, 1);
}

.btn.btn-prim.btn-transparent {
  border: 1px solid rgba(1, 34, 105, 1);
  background: #fff;
  color: rgba(1, 34, 105, 1);
}

.btn.btn-prim.btn-transparent:hover {
  border: 1px solid rgba(1, 34, 105, 1);
  background: rgba(1, 34, 105, 1);
  color: #fff;
}

.modal-footer .btn.btn-prim {
  width: auto;
  min-width: 100px;
}
.btn.btn-prim-scan {
  padding: 10px 30px;
  width: 100%;
  line-height: 19px;
  font-size: 13px;
  font-family: Arial;
  font-weight: 500;
  word-wrap: break-word;
  background: rgba(1, 34, 105, 1);
  color: #fff;
  border: 1px solid rgba(1, 34, 105, 1);
  transition: 0.3s;
}
.btn.btn-prim-scan.btn-transparent-scan {
  border: 1px solid rgba(1, 34, 105, 1);
  background: #fff;
  color: rgba(1, 34, 105, 1);
}
.btn.btn-prim-scan:hover,
.btn.btn-prim-scan:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(1, 34, 105, 1);
  background: #fff;
  color: rgba(1, 34, 105, 1);
}
.btn.btn-prim-scan.btn-transparent-scan:hover {
  border: 1px solid rgba(1, 34, 105, 1);
  background: rgba(1, 34, 105, 1);
  color: #fff;
}
.btn.btn-prim-table {
  padding: 10px 10px;
  width: 10%;
  line-height: 19px;
  font-size: 13px;
  font-family: Arial;
  font-weight: 500;
  word-wrap: break-word;
  background: rgba(1, 34, 105, 1);
  color: #fff;
  border: 1px solid rgba(1, 34, 105, 1);
  transition: 0.3s;
}
.btn.btn-prim-table:hover,
.btn.btn-prim-table:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(1, 34, 105, 1);
  background: #fff;
  color: rgba(1, 34, 105, 1);
}
.btn.btn-prim-slider {
  padding: 3px 8px;
  width: 100%;
  line-height: 10px;
  font-size: 6px;
  font-family: Arial;
  font-weight: 500;
  word-wrap: break-word;
  background: rgba(1, 34, 105, 1);
  color: #fff;
  border: 1px solid rgba(1, 34, 105, 1);
  transition: 0.3s;
}
.btn.btn-prim-slider:hover,
.btn.btn-prim-slider:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(1, 34, 105, 1);
  background: #fff;
  color: rgba(1, 34, 105, 1);
}
.btn.btn-prim-slider.btn-transparent-slider {
  border: 1px solid rgba(1, 34, 105, 1);
  background: #fff;
  color: rgba(1, 34, 105, 1);
}
.btn.btn-prim-slider.btn-transparent-slider:hover {
  border: 1px solid rgba(1, 34, 105, 1);
  background: rgba(1, 34, 105, 1);
  color: #fff;
}

.btn-primary-outline {
  padding: 3px 7px;
  width: 100%;
  line-height: 19px;
  font-size: 9px;
  font-family: Arial;
  font-weight: 500;
  word-wrap: break-word;
  background: transparent;
  color: rgba(1, 34, 105, 1);
  border: 1px solid rgba(1, 34, 105, 1);
  transition: 0.3s;
}

.form-wrap a {
  color: #222222;
  font-size: 13px;
  font-family: Arial;
  font-weight: 700;
  word-wrap: break-word;
  margin-top: 30px;
  display: inline-block;
  text-decoration: none;
  padding-bottom: 15px;
}

/* form css */
.body-wrap {
  overflow-x: hidden;
}

/* sidebar */
.col.col-sidebar {
  background: #fff;
  height: 100%;
  max-width: 100%;
  width: 300px;
  flex: 0 0 auto;
  position: fixed;
  top: 0;
  /* left: 0; */
  overflow-x: hidden;
  overflow-y: auto;
}

/* width */
.col.col-sidebar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.col.col-sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.col.col-sidebar::-webkit-scrollbar-thumb {
  background: rgba(1, 34, 105, 1);
  border-radius: 10px;
}

/* Handle on hover */
.col.col-sidebar::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 34, 105, 0.7);
}

.sidebar_wrap {
  padding: 30px 30px;
}

.logo-sidebar-wrap {
  margin-bottom: 35px;
}

section.sidebar_wrap ul li {
  padding: 13px 20px;
}

section.sidebar_wrap ul li a {
  color: #999999;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
  text-decoration: none;
  transition: 0.3s;
}

section.sidebar_wrap ul li a.active,
section.sidebar_wrap ul li a:hover {
  color: #012269;
  font-size: 12px;
  font-family: Arial;
  font-weight: 700;
  word-wrap: break-word;
}

section.sidebar_wrap ul li img {
  width: 18px;
  object-fit: contain;
  margin-right: 20px;
  filter: brightness(0);
  opacity: 0.3;
  transition: 0.3s;
}

section.sidebar_wrap ul li .active img,
section.sidebar_wrap ul li a:hover img {
  filter: brightness(1);
  opacity: 1;
}

button#dropdownNot {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #fff;
  width: 34px;
  height: 34px;
  margin-right: 30px;
  padding: 5px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}

button#dropdownNot:focus {
  outline: none;
}

button#dropdownNot:after {
  display: none;
}

ul.dropdown-menu {
  background: #fff;
  width: 250px;
  border-radius: 5px;
  border: 0.5px #eeeeee solid;
  padding: 0;
}

ul.dropdown-menu > li {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

ul.dropdown-menu h3 {
  font-size: 15px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
  color: #222222;
  margin-bottom: 0;
}

span.not_num_icon {
  width: 14px;
  height: 14px;
  background: rgba(1, 34, 105, 1);
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 8px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
  border-radius: 7px;
}

ul.dropdown-menu li {
  padding-top: 12px;
  padding-bottom: 12px;
  padding: 0 10px;
}

ul.dropdown-menu .notification_heads {
  padding: 12px 10px;
}

ul.dropdown-menu > li li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px 0;
  cursor: pointer;
  transition: 0.3s;
}

ul.dropdown-menu > li li.not_list.unread {
  background: rgba(1, 34, 105, 0.03);
}

ul.dropdown-menu .dropdown-item.active,
ul.dropdown-menu .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

ul.dropdown-menu .dropdown-item:focus,
ul.dropdown-menu .dropdown-item:hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: transparent;
}

.not_text_wrap {
  max-width: 100%;
  flex: 0 0 auto;
  width: calc(100% - 70px);
}

ul.dropdown-menu > li li:hover {
  background: rgba(1, 34, 105, 0.03);
}

.not_text_wrap p {
  color: #222222;
  font-size: 10px;
  font-family: Arial;
  font-weight: 400;
  line-height: 12.5px;
  word-wrap: break-word;
  margin-bottom: 0;
  white-space: initial;
}

.not_prof_wrap {
  max-width: 100%;
  flex: 0 0 auto;
  width: 30px;
  margin-right: 10px;
}
.not_prof_img {
  margin-bottom: 10px;
}

.not_time_wrap {
  max-width: 100%;
  flex: 0 0 auto;
  width: 30px;
  text-align: right;
}

.not_time_wrap span {
  color: #aaaaaa;
  font-size: 10px;
  font-family: Arial;
  font-weight: 400;
  line-height: 12.5px;
  word-wrap: break-word;
  display: block;
  text-align: right;
}

/* sidebar */
.col.col-dashboard {
  height: 100%;
  max-width: 100%;
  width: calc(100% - 300px);
  flex: 0 0 auto;
  padding: 0 40px;
  margin-left: 300px;
}

header {
  padding: 20px 0 45px;
}

.wel-not h1 {
  color: #012269;
  font-size: 22px;
  font-family: Arial;
  font-weight: 700;
  word-wrap: break-word;
}

.prof-wrap {
  display: flex;
  align-items: center;
}

.prof-sett {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.det-prof {
  margin-right: 15px;
}

.name-wrap h5 {
  color: black;
  font-size: 15px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
}

.name-wrap p {
  color: #777777;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 0;
}

.btn.tb-btn,
.general-dashboard table td .link-edit {
  background-color: rgba(0, 62, 182, 0.07);
  color: #003eb6;
  border-radius: 5px;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
  padding: 8px 30px;
  margin-left: 20px;
  transition: 0.3s;
}

.btn.tb-btn:hover,
.link-edit.tb-btn:hover {
  background-color: #003eb6;
  color: #fff;
}

.btn.tb-btn,
.link-edit.tb-btn {
  padding: 8px 18px;
  min-width: 80px;
}

.selected-btn {
  background-color: rgba(0, 62, 182, 0.07);
  color: #003eb6;
  border-radius: 5px;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
  padding: 6px 8px;
  margin-left: 20px;
  transition: 0.3s;
}

.btn.btn-delete {
  color: #b60000;
  background-color: rgba(182, 0, 0, 0.07);
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
}

.btn.btn-delete:hover {
  background-color: #b60000;
  color: #fff;
}

.btn.btn-success {
  color: #fff;
  background-color: #1f8c15;
}

.btn.btn-success:hover {
  background-color: #1f8c15;
  border: 1px solid #1f8c15;
  color: #fff;
}

.btn.btn-prim.btn-danger {
  background: #b60000;
  border: 1px solid #b60000;
}

.btn.btn-prim.btn-danger:hover {
  background: #fff;
  border: 1px solid #b60000;
  color: #b60000;
}

.form-box.card-box {
  background: white;
  border-radius: 10px;
  border: 0.5px #eeeeee solid;
  padding: 30px 20px;
}

.col.col-user-accs .nav-pills .nav-link .usr-det p {
  margin-bottom: 0;
  color: #999999;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  line-height: 15px;
  word-wrap: break-word;
}

html body .nav-pills .nav-link.active,
html body .nav-pills .show > .nav-link {
  background-color: rgba(1, 34, 105, 0.05);
}

.col.input-btn .btn.btn-prim:hover {
  color: rgba(1, 34, 105, 1) !important;
}

.btn-check.btn-prim:checked + .btn.btn-prim:focus-visible,
.btn.btn-prim.active:focus-visible,
.btn.btn-prim.show:focus-visible,
.btn.btn-prim:first-child:active:focus-visible,
:not(.btn-check) + .btn.btn-prim:active:focus-visible,
.btn.btn-prim:focus {
  border: 1px solid rgba(1, 34, 105, 1) !important;
  background: #fff !important;
  color: rgba(1, 34, 105, 1) !important;
}

.incoming-call-sec h3 {
  color: black;
  font-size: 22px;
  font-family: Arial;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 38.5px;
  letter-spacing: 2.2px;
  word-wrap: break-word;
  margin-bottom: 30px;
}

.call-detail-bar {
  background: rgba(0, 0, 0, 0.1);
  padding: 20px 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rcvr-img {
  border-radius: 20px;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  width: 120px;
  height: 120px;
  margin-right: 20px;
}

.rcvr-img {
  border-radius: 20px;
  object-fit: cover;
  width: 120px;
  height: 120px;
}

.rcvr-detail {
  display: flex;
}

.incoming-call-sec .rcvr-naming h3 {
  margin-bottom: 0;
  color: black;
  font-size: 25px;
  font-family: Arial;
  font-weight: 400;
  line-height: 43.75px;
  word-wrap: break-word;
  padding-top: 10px;
}

.rcvr-naming p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  font-family: Arial;
  font-weight: 400;
  line-height: 25px;
  word-wrap: break-word;
  margin-bottom: 0;
}

.icons-wrap-options {
  display: flex;
}

.icon-dec {
  background: #e72626;
  border-radius: 20px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-att-voice,
.icon-att-vid {
  background: #30b62e;
  border-radius: 20px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.call-time {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  backdrop-filter: blur(5px);
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 8px 20px;
  min-width: 100px;
  text-align: center;
}

.call-time p {
  margin-bottom: 0;
}

.col.col-chat-screen .col.input-btn {
  width: 100px;
}

.col.col-chat-screen .col.input-fld {
  width: calc(100% - 100px);
}

.bottom-bar-call {
  position: absolute;
  width: 100%;
  bottom: 20px;
  z-index: 999;
  text-align: center;
}
.icons-wrap-end-call {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 230px;
  left: 41px;
}

.icon-call {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  backdrop-filter: blur(4px);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.dec-call-video {
  backdrop-filter: blur(4px);
  width: 50px;
  height: 50px;
  background-color: rgba(231, 38, 38, 1);
}

.bottom-bar-call.seperat-row {
  position: unset;
  padding-top: 20px;
}

.bottom-bar-call.seperat-row .icon-call {
  background-color: rgba(0, 0, 0, 0.25);
}

.bottom-bar-call.seperat-row .dec-call-video {
  backdrop-filter: blur(4px);
  width: 60px;
  height: 60px;
  background-color: rgba(231, 38, 38, 1);
}

.message-body .icon-wrap {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: rgba(186, 221, 186, 1);
  margin-right: 20px;
  flex-shrink: 0;
  max-width: 100%;
  flex: 0 0 auto;
}

.message-body .icon-wrap img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.col.col-chat-screen .cht-wrap {
  padding: 20px 20px;
  height: 305px;
  overflow: auto;
}

.general-dashboard .way_find_table table td:nth-last-child(1) {
  text-align: end;
}

.btn-add-wrap {
  display: flex;
  justify-content: space-between;
}

.btn.btn-gray {
  background: #eeeeee;
  border-radius: 5px;
  color: #222222;
  font-size: 13px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
  padding: 12px 20px;
  min-width: 135px;
}

.upld-img-wrap {
  width: 100%;
  height: 90px;
  background: #ffd5dc;
  border-radius: 5px;
  margin: 20px 0;
  position: relative;
}

.vid-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 25px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 10px;
}

.defected-text {
  color: #ff5c00;
}

.form-box.card-box.appnt-set-box {
  padding: 20px;
}

.form-box.card-box.appnt-set-box h3 {
  color: #222222;
  font-size: 20px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
}

.form-box.card-box.appnt-set-box p {
  color: #999999;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
}

.row-top-appnt {
  margin-bottom: 75px;
}

/* media queries */
@media (max-height: 650px) {
  .media-footer {
    position: unset;
    width: 100%;
    padding-bottom: 35px;
  }
}

@media (min-width: 992px) {
  .col-xl-3.col-lg-3.cust-col-3 {
    width: 100%;
  }

  .col-lg-6.cust-col-6 {
    width: 100%;
  }

  .moda .label-wrap {
    margin-bottom: 15px;
  }

  .col-lg-3.col-cust-screens-3 {
    width: 20.8%;
  }

  .col-lg-9.col-cust-screens-9 {
    width: 79.2%;
  }
}

@media (min-width: 1199px) {
  .col-xl-3.col-lg-3.cust-col-3 {
    width: 230px;
  }

  .col-lg-6.cust-col-6 {
    width: calc(100% - 460px);
  }

  .col-lg-3.col-cust-screens-3 {
    width: 20.8%;
  }

  .col-lg-9.col-cust-screens-9 {
    width: 79.2%;
  }
}

@media (min-width: 1399px) {
  .col-lg-3.col-cust-screens-3 {
    width: 20.8%;
  }

  .col-lg-9.col-cust-screens-9 {
    width: 79.2%;
  }
}

@media (max-width: 1500px) {
  .inner-det-wrap {
    padding-right: 0px;
  }

  .inner-det-wrap .col.col-name {
    width: 18%;
  }

  .inner-det-wrap .col.col-email {
    width: 31%;
  }

  .inner-det-wrap .col.col-contact {
    width: 22%;
  }

  .inner-det-wrap .col.col-date {
    width: 29%;
  }
}

@media (max-width: 1399px) {
  .form-box.card-box .details-wrap-appnt h4 {
    font-size: 13px;
  }

  .btn.tb-btn,
  .link-edit.tb-btn {
    padding: 5px 15px;
    min-width: inherit;
    margin-left: 10px;
  }

  .col.col-sidebar {
    width: 225px;
    padding: 0;
  }

  section.sidebar_wrap ul li {
    padding: 11px 20px;
  }

  .col.col-dashboard {
    width: calc(100% - 225px);
    padding: 0 20px 0 10px;
    margin-left: 225px;
  }

  .sidebar_wrap {
    padding: 30px 10px 30px 20px;
  }

  .general-dashboard table td {
    padding: 0px 10px;
  }

  .general-dashboard table th {
    padding: 10px 10px;
  }

  .form-btn-wrap .btn {
    min-width: 200px;
  }

  .form-box.card-box {
    padding: 30px 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .inpt-wrap input,
  .inpt-wrap select,
  .inpt-wrap textarea,
  .file-wrap {
    padding: 10px 20px;
    font-size: 11px;
    line-height: 17px;
  }

  .form-box.card-box p {
    font-size: 11px;
    line-height: 11px;
  }
}

@media (max-width: 1200px) {
  .scan-img-appnt {
    margin-top: 25px;
  }

  .form-box.card-box.appnt-set-box h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .conditions-appnt .d-flex.align-items-center.justify-content-end {
    justify-content: left !important;
    margin-top: 25px;
  }

  .col-lg-cust-5 {
    width: 25%;
  }

  .col.col-dashboard {
    width: calc(100% - 250px);
    padding: 0px 20px 0 10px;
    margin-left: 250px;
  }

  .col.col-sidebar {
    width: 250px;
  }

  section.sidebar_wrap ul li {
    padding: 13px 10px;
  }

  .btn.tb-btn,
  .link-edit.btn.tb-btn {
    padding: 5px 10px;
    min-width: inherit;
    margin-left: 3px;
    font-size: 10px;
  }
}

@media (max-width: 991px) {
  .mob-logo {
    display: block;
  }

  header .col-lg-6 {
    order: 2;
  }

  header .col-lg-6.text-end {
    order: 1;
  }

  .wel-not {
    padding: 75px 0 0;
  }

  html body .prof-sett {
    position: fixed;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
    padding: 15px 10px;
    justify-content: space-between;
    z-index: 9;
  }

  .det-prof {
    display: none;
  }

  .mob_btn_hambrgr {
    position: unset;
    margin: 0;
    width: 98px;
  }

  .mob_btn_hambrgr img {
    width: auto;
  }

  .logo-wrap.mob-logo img {
    width: 75px;
  }

  .all-head {
    display: flex;
  }

  section.sidebar_wrap .logo-wrap {
    opacity: 0;
    visibility: hidden;
    position: relative;
    z-index: -1;
  }

  .inner-det-wrap .col.col-name {
    width: 100%;
    padding: 0 30px !important;
  }

  .inner-det-wrap .col.col-email {
    width: 100%;
    padding: 0 30px !important;
  }

  .inner-det-wrap .col.col-contact {
    width: 100%;
    padding: 0 30px !important;
  }

  .inner-det-wrap .col.col-date {
    width: 100%;
    padding: 0 30px !important;
  }

  .scan-img-appnt {
    text-align: center;
  }

  .conditions-appnt .appnt-alert {
    margin-left: 0;
    margin-top: 15px;
    padding: 7px 30px;
    font-size: 10px;
  }

  .inner-det-wrap {
    padding-right: 0;
  }

  .row-top-appnt {
    margin-bottom: 25px;
  }

  .form-box.card-box.appnt-set-box .details-wrap-appnt h3 {
    font-size: 15px;
    padding: 10px 0;
    margin-bottom: 30px;
  }

  .form-box.card-box .details-wrap-appnt h4 {
    margin-bottom: 20px;
  }

  .inner-det-wrap .col {
    width: 100%;
  }

  .btns-wrap.appnt .btn {
    margin: 5px 0 !important;
  }

  .btns-wrap.appnt {
    text-align: center;
  }

  .scan-img-appnt {
    margin-top: 20px;
  }

  .conditions-appnt .d-flex.align-items-center.justify-content-end {
    flex-wrap: wrap;
    justify-content: center !important;
  }

  .user-wrap h3 {
    font-size: 18px;
  }

  .form-box.card-box.appnt-set-box h3 {
    color: #222222;
    font-size: 12px;
    font-family: Arial;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 6px;
  }

  .form-box.card-box.appnt-set-box p,
  .user-wrap p {
    font-size: 8px;
  }

  .col.col-sidebar {
    transition: 0.5s linear;
  }

  .col.col-sidebar.sidebar.active {
    left: 0;
  }

  .mob_btn_hambrgr {
    display: flex;
  }

  .cont-detail.d-flex {
    flex-wrap: wrap;
  }

  .cont-detail a {
    width: 100%;
  }

  .media-opt-wrap {
    margin-bottom: 15px;
  }

  .media-selection h2 {
    margin-bottom: 25px;
  }

  .col-lg-3.col-cust-3,
  .col-lg-9.col-cust-9 {
    width: 100%;
  }

  .media-footer {
    position: unset;
    width: 100%;
    padding-bottom: 35px;
  }

  .scanner {
    text-align: center;
    margin: 0 0 20px;
  }

  .scan-box-wrap {
    padding: 30px 60px 30px 40px;
  }

  .label-wrap {
    margin-bottom: 15px;
  }

  .upld-img-wrap {
    height: 250px;
  }

  .modal .main-img {
    height: 500px !important;
    margin-top: 30px;
  }

  .modal .label-wrap {
    margin-bottom: 20px;
  }

  .mob-1 {
    order: 1;
  }

  .mob-2 {
    order: 2;
  }

  .cont_form_wrap {
    padding: 30px;
  }

  .form-wrap input,
  .form-wrap select {
    margin-bottom: 10px;
  }

  .col.col-sidebar {
    width: 250px;
    position: fixed;
    left: -100%;
    z-index: 9;
  }

  .col.col-dashboard {
    width: 100%;
    padding: 0 20px;
    margin-left: 0;
  }

  .wel-not h1 {
    text-align: center;
    font-size: 16px;
  }

  .prof-sett {
    justify-content: center;
  }
  .site-logo img {
    display: none;
  }
  .form-box.card-box {
    margin-top: 50px;
  }
}

@media (max-width: 769px) {
  .mob-logo {
    display: block;
  }

  header .col-lg-6 {
    order: 2;
  }

  header .col-lg-6.text-end {
    order: 1;
  }

  .wel-not {
    padding: 75px 0 0;
  }

  .site-logo img {
    display: none;
  }
  .site-logo img {
    display: none;
  }
  .form-box.card-box {
    margin-top: 50px;
  }

  .prof-sett {
    position: fixed;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
    padding: 15px 10px;
    justify-content: space-between;
    z-index: 9;
  }

  .det-prof {
    display: none;
  }

  .mob_btn_hambrgr {
    position: unset;
    margin: 0;
    width: 98px;
  }

  .mob_btn_hambrgr img {
    width: auto;
  }

  .logo-wrap.mob-logo img {
    width: 75px;
  }

  .all-head {
    display: flex;
  }

  section.sidebar_wrap .logo-wrap {
    opacity: 0;
    visibility: hidden;
    position: relative;
    z-index: -1;
  }

  .btns-wrap.appnt.btns-wrap-appt .btn {
    margin: 5px 10px !important;
    min-width: 40%;
  }

  .btns-wrap.appnt.btns-wrap-appt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .form-box.card-box.appnt-set-box h3,
  .form-box.card-box.appnt-set-box p {
    text-align: center;
  }

  .col-lg-cust-5 {
    width: 50%;
    padding: 0 10px;
  }

  .scanner {
    text-align: center;
    margin: 0 0 20px;
  }

  .bar-code-box .img {
    padding: 5px;
    margin-right: 10px;
  }

  .bar-code-box .img img {
    max-width: 30px;
    width: 100%;
  }

  .bar-code-box {
    margin-right: 20px;
  }

  .bar-code-text h3 {
    font-size: 16px;
  }

  .vide-bar-bottom {
    padding: 15px 20px;
  }

  .video-box img.play-btn {
    max-width: 100px;
  }

  .justify-content-center-mob {
    justify-content: center;
  }

  .seq-wrap > span {
    position: absolute;
    left: 0;
  }

  .seq-wrap {
    text-align: center;
    margin: 0 auto;
  }

  .seq-wrap .upld-img-wrap {
    margin: 20px auto;
  }

  .seq-wrap .btn.btn-prim {
    margin-left: 0;
  }

  .col.col-video-screen,
  .col.col-user-accs,
  .col.col-user-msgs {
    width: 100%;
  }

  .user-wrap {
    margin-bottom: 20px;
  }

  .vide-sec-whole-wrap {
    max-height: initial;
    height: auto;
  }

  .bottom-bar-call.seperat-row {
    position: unset;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .label-wrap {
    margin-bottom: 15px;
  }

  .form-btn-wrap .btn {
    min-width: initial;
    margin-left: 10px;
  }

  .call-detail-bar {
    padding: 20px;
    display: block;
  }

  .incoming-call-sec h3 {
    color: black;
    font-size: 17px;
    font-family: Arial;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 38.5px;
    letter-spacing: 1.2px;
    word-wrap: break-word;
    margin-bottom: 30px;
  }

  .icons-wrap-options {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0 0;
  }

  .incoming-call-sec .rcvr-naming h3 {
    font-size: 20px;
  }

  .rcvr-naming p {
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .col-lg-cust-5 {
    width: 75%;
    padding: 0 10px;
  }

  .scan-box-wrap {
    padding: 30px 30px;
  }

  .btn,
  .btn.btn-prim {
    margin-bottom: 10px;
  }

  .bar-code-box .img img {
    max-width: 20px;
    width: 100%;
  }

  .bar-code-box .img {
    padding: 7px;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  .site-logo img {
    display: none;
  }
  .form-box.card-box {
    margin-top: 50px;
  }
}

.calc-wrap-main img {
  max-width: 100%;
}

.calc-wrap-main {
  margin: 65px 0;
}

.calc-wrap {
  padding: 23px 19px 22px 24px;
  border-radius: 27px;
  background: rgba(255, 255, 255, 1);
  max-width: 434px;
  width: 100%;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
}

.cal-screen input {
  width: 100%;
  /* padding: ; */
  padding: 20px 10px;
  color: #111;
  background: rgba(230, 230, 230, 1);
  border-radius: 9px;
  margin-bottom: 37px;
  border: none;
  outline: none;
  font-size: 24px;
  font-weight: 400;
  color: #111;
}

.keys-wrap {
  background: #e6e6e6;
  border-radius: 9px;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 6px;
  /* height: 280px; */
}

.btn-key {
  width: 100%;
  /* height: 100%; */
  margin-bottom: 6px;
  font-size: 24px;
  font-weight: 400;
  color: #111;
  border-radius: 4px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: none;
  padding: 20px 0 24px;
  position: relative;
}

button.btn-key > * {
  width: 100%;
  font-style: normal;
}

button.btn-key span {
  line-height: 24px;
}

.btn-delt {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  flex-wrap: wrap;
}

.empty-div-cal {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
}

.btn-key span {
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  position: absolute;
  width: 100%;
  bottom: 14px;
}

.continue-btn {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  font-family: Arial;
  margin-top: 37px;
  color: #fff;
  background: #012269;
  padding: 14.5px 0;
  text-align: center;
  border-radius: 6.1px;
  border: none;
}

.complete-wrap h3 {
  color: #012269;
  font-size: 18px;
  font-weight: 700;
  word-wrap: break-word;
  margin-top: 40px;
  margin-bottom: 15px;
}

.complete-wrap h4 {
  color: black;
  font-size: 18px;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 15px;
}

.complete-wrap a {
  color: #012269;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  word-wrap: break-word;
}

.steps-wrap {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}

.step-cont {
  position: relative;
  padding-right: 78px;
}

.step-cont:after {
  content: "";
  position: absolute;
  width: 74px;
  height: 3px;
  border-bottom: 1px #012269 dotted;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.step-cont:nth-last-child(1):after {
  display: none;
}

.step-cont:nth-last-child(1) {
  padding-right: 0;
}

.step-cont span {
  color: #012269;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 1px #012269 dotted;
  border: 2px solid #f4f8f9;
  position: relative;
  background-color: #f4f8f9;
}

.active.step-cont span {
  outline: 1px dotted #012269;
  background: #012269;
  color: #fff;
}

/* additional css */
::-webkit-scrollbar {
  width: 1px;
}

.qrCodeImagelarge {
  width: 200px;
  /* Adjust this to your desired larger size */
  height: 200px;
  /* Adjust this to your desired larger size */
  cursor: pointer;
}

#html5-qrcode-button-camera-stop {
  background: #012269 !important;
  border: 1px solid #012269;
  color: white !important;
  border-radius: 5px !important;
  padding: 5px !important;
}

#html5-qrcode-button-camera-start {
  background: #012269 !important;
  border: 1px solid #012269;
  color: white !important;
  border-radius: 5px !important;
  padding: 5px !important;
}

#html5-qrcode-anchor-scan-type-change {
  display: none !important;
}

.counter-sec {
  font-family: "Roboto Condensed", cursive;
  text-align: center;
  line-height: 100px;
  font-size: 28px;
  font-weight: normal;
  top: 50%;
  left: 50%;
  color: #939393;
}

.counter-sec .counter {
  font-size: 50px;
  width: 80px;
  height: 50px;
  font-family: "Oswald", sans-serif;
  display: inline-block;
  padding: 0 30px 0 10px;
  perspective: 200px;
  position: relative;
  top: -7px;
}

.counter-value {
  padding: 20px;
  border: 1px solid #1a1a91;
  border-radius: 50px;
  color: red;
}

.user-video {
  border-radius: 10px;
  border: 1px white solid;
}

.vide-img-wrap video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* new css*/
.persona-img {
  width: 108px;
  height: 92px;
  border-radius: 10px;
  /* border: 1px white solid */
}

.small-screen {
  /* background: rgba(255, 255, 255, 0.50);
    border-radius: 10px; */
  position: absolute;
  top: 20px;
  right: 20px;
  min-width: 100px;
  text-align: center;
}

/* //locker styling */

.form-box.card-box-locker {
  background: white;
  border-radius: 10px;
  border: 0.5px #eeeeee solid;
  padding: 30px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lockers {
  width: auto;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  margin-left: 120px;
}

.lockers-stringee {
  width: auto;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  margin-left: 50px;
}
.lockers img {
  width: 150px;
}
.lockers-stringee img {
  width: 130px;
}
.lockers-short img {
  width: 132px;
}
.lockers-short-stringee img {
  width: 115px;
}
.half-locker {
  margin-top: 20px;
}
.col-1-7 {
  width: 16.28%;
  padding: 0;
  margin: 0;
}
.video-attendant img {
  height: 135px;
  width: 127px;
  margin: 40px 130px;
}
.video-call-attendant {
  width: 370px;
}
.video-call-button {
  margin-left: 150px;
}
.video-attendant-stringee img {
  height: 113px;
  width: 105px;
  margin: 83px 60px;
}
.video-call-btn {
  margin-left: 140px;
}

.live-streaming canvas {
  border-radius: 10px;
  width: 70%;
}
.locker-button-text {
  position: absolute;
  top: 25%;
  left: 100%; /* Center horizontally */
  transform: translateX(-50%);
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
}

.locker-button-text-stringee {
  position: absolute;
  top: 22%;
  left: 87%; /* Center horizontally */
  transform: translateX(-50%);
  border-radius: 4px;
  color: #000000;
  font-size: 12px;
}

.locker-button-text-stringee-small {
  position: absolute;
  top: 59%;
  left: 83%; /* Center horizontally */
  transform: translateX(-50%);
  border-radius: 4px;
  color: #000000;
  font-size: 12px;
}

.locker-button-text-small {
  left: 30px;
  top: 105px;
}

@media (max-width: 991px) {
  .lockers-short img {
    width: 85px !important;
    margin-left: 10px;
  }
  .lockers {
    height: 100%;
    position: relative;
    box-sizing: border-box;
    margin-left: 70px;
  }

  .lockers img {
    width: 100px;
  }
  .col-1-7 {
    width: 16.28%;
  }

  .video-attendant img {
    height: 90px;
    width: 90px;
    margin: 20px 75px;
  }

  .form-box.card-box-locker {
    margin-top: 60px;
    padding: 30px 5px;
  }

  .video-wrapper {
    position: absolute;
    top: 2% !important;
    right: 3% !important;
    z-index: 99999;
    height: 80px !important;
    width: 100px !important;
  }
  /* .local-stream {
    height: 17% !important;
    width: 20% !important;
    position: absolute;
    top: 9% !important;
    right: 10% !important;
  } */
  .lockers-stringee img {
    width: 90px;
  }
  .lockers-short-stringee img {
    width: 75px;
  }
  .video-attendant-stringee img {
    height: 63px;
    width: 64px;
    margin: 64px 65px;
  }
  .icons-wrap-end-call-locker {
    position: absolute;
    top: 66%;
    left: 27%;
  }
  .video-call-attendant {
    width: 245px;
  }
  .video-call-button {
    margin-left: 90px;
  }
}

@media (max-width: 820px) {
  .lockers {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    margin-left: 70px;
  }

  .lockers img {
    width: 105px;
  }
  .col-1-7 {
    width: 16.28%;
  }

  .video-attendant img {
    height: 80px;
    width: 80px;
    margin: 50px 80px;
  }
  .lockers-short img {
    width: 91px;
  }
}

@media (max-width: 769px) {
  .form-box.card-box-locker {
    margin-top: 70px;
    padding: 30px 5px;
  }
  .video-wrapper {
    position: absolute;
    top: -7% !important;
    right: -8% !important;
    z-index: 99999;
    height: 390px !important;
    width: 450px !important;
  }
  .lockers-stringee img {
    width: 90px;
  }
  .lockers-short-stringee img {
    width: 75px;
  }
  .video-attendant-stringee img {
    height: 63px;
    width: 64px;
    margin: 64px 65px;
  }
  .lockers {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    margin-left: 60px;
  }

  .lockers img {
    width: 112px;
  }
  .col-1-7 {
    width: 16.28%;
  }

  .video-attendant img {
    height: 90px;
    width: 90px;
    margin: 30px 70px;
  }
  .video-call-attendant {
    width: 245px;
  }
  .video-call-button {
    margin-left: 90px;
  }
  .icons-wrap-end-call-locker {
    position: absolute;
    top: 70%;
    left: 27%;
  }
  .user-video-screens {
    margin-left: 70px;
  }
  .local-streaming-side {
    margin-top: 50px;
    height: 347px !important;
  }

  .remote-streaming-side {
    margin-top: 50px;
    margin-right: 0px; /* 20px */
    height: 347px !important;
  }
  .calling-area {
    margin-left: 8px !important;
  }
}

@media (max-width: 575px) {
  .lockers {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    margin-left: 35px;
  }
  .lockers img {
    width: 80px;
  }
  .col-1-7 {
    width: 16.28%;
  }
  .video-attendant img {
    height: 55px;
    width: 60px;
    margin: 24px 46px;
  }
  .video-call-attendant {
    width: 200px;
  }
  .video-call-button {
    margin-left: 50px;
  }
  .video-wrapper {
    position: absolute;
    top: 2% !important;
    right: 2% !important;
    z-index: 99999;
    height: 80px !important;
    width: 105px !important;
  }
}

@media (max-width: 441px) {
  .form-box.card-box-locker {
    margin-top: 80px;
  }
  .lockers {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    margin-left: 20px;
  }

  .lockers img {
    width: 53px;
  }
  .col-1-7 {
    width: 16.28%;
  }
  .video-attendant img {
    height: 45px;
    width: 45px;
    margin: 2px 23px;
  }
  .video-attendant {
    margin-right: 91px;
  }
  .half-locker {
    margin-top: 12px;
  }
  .lockers-stringee img {
    width: 65px;
  }
  .lockers-short-stringee img {
    width: 56px;
  }
  .video-attendant-stringee img {
    height: 42px;
    width: 42px;
    margin: 47px 34px;
  }
  .lockers-stringee {
    margin-left: 23px;
  }
  .video-wrapper {
    position: absolute;
    top: 2% !important;
    right: 2% !important;
    z-index: 99999;
    height: 80px !important;
    width: 105px !important;
  }
  /* .local-stream {
    height: 21% !important;
    width: 23% !important;
    position: absolute;
    top: 5% !important;
    right: 2% !important;
  } */
  .video-call-attendant {
    width: 185px;
  }
  .video-call-button {
    margin-left: 25px;
  }
  .icons-wrap-end-call {
    position: absolute;
    top: 75%;
    left: 9%;
  }
  .icons-wrap-end-call-locker {
    position: absolute;
    top: 55%;
    left: 0%;
  }
  .user-video-screens {
    margin-right: 120px;
  }
  .calling-area {
    margin-left: 20px !important;
  }
  .local-streaming-side {
    border: 2px solid black;
    width: 270px !important;
    height: 205px !important;
  }

  .remote-streaming-side {
    border: 2px solid black;
    width: 270px !important;
    height: 205px !important;
  }
}

@media (max-width: 376px) {
  .form-box.card-box-locker {
    margin-top: 85px;
  }
  .lockers {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    margin-left: 13px;
  }

  .lockers img {
    width: 47px;
  }
  .col-1-7 {
    width: 16.28%;
  }
  .video-attendant img {
    height: 50px;
    width: 50px;
    margin: -3px 9px;
  }
  .video-attendant {
    margin-right: 109px;
  }
  .half-locker {
    margin-top: 10px;
  }
  .lockers-stringee {
    margin-left: 14px;
  }
  .lockers-stringee img {
    width: 54px;
  }
  .lockers-short-stringee img {
    width: 46px;
  }
  .video-attendant-stringee img {
    height: 42px;
    width: 42px;
    margin: 37px 21px;
  }

  .icons-wrap-end-call-locker {
    position: absolute;
    top: 55%;
    left: 0%;
  }
  .video-call-attendant {
    width: 185px;
  }
  .video-call-button {
    margin-left: 15px;
  }
  .local-streaming-side {
    border: 2px solid black;
    width: 270px !important;
    height: 205px !important;
  }

  .remote-streaming-side {
    border: 2px solid black;
    width: 270px !important;
    height: 205px !important;
  }
  .video-wrapper {
    position: absolute;
    top: 2% !important;
    right: 2% !important;
    z-index: 99999;
    height: 80px !important;
    width: 105px !important;
  }
}
@media (max-width: 360px) {
  .form-box.card-box-locker {
    margin-top: 85px;
  }
  .video-attendant img {
    height: 40px;
    width: 40px;
    margin: 32px 17px;
  }
}
@media (max-width: 280px) {
  .lockers {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    margin-left: 13px;
  }

  .lockers img {
    width: 34px;
  }
  .col-1-7 {
    width: 16.28%;
  }
  .video-attendant img {
    height: 23px;
    width: 23px;
    margin: 26px 19px;
  }
  .half-locker {
    margin-top: 5px;
  }
}

.video-wrapper {
  position: absolute;
  top: 1%;
  right: 1%;
  z-index: 99999;
  height: 100px; /* Fixed height */
  width: 120px; /* Fixed width */
}

.local-stream {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-video-screens {
  width: 400px;
  /* height: 390px; */
  height: 305px;
  position: relative;
  margin-top: 3%;
  border: 0.5px solid rgb(110, 110, 110);
  margin-right: 85px;
}

.local-streaming-side {
  border: 1.5px solid rgb(107, 107, 107);
  width: 460px;
  margin-left: 0px; /* 27px */
  height: 347px;
}

.remote-streaming-side {
  border: 1.5px solid rgb(107, 107, 107);
  width: 460px;
  height: 347px;
}

.calling-area {
  margin-left: 50px;
}

.call-time {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  backdrop-filter: blur(5px);
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 8px 20px;
  min-width: 100px;
  text-align: center;
  z-index: 1000;
}
.call-time p {
  margin-bottom: 0;
}
.table-num {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
}
